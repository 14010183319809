import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { TokenService } from '../shared/services/token.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _settings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sessionLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  workerCurrentTime = 0;

  constructor(
    private http: HttpService,
    private tokenService: TokenService,
    private router: Router
  ) {}
  login(formValue) {
    return this.http.post('login', formValue, null, true);
  }
  session(token?: string) {
    const accessToken = token || sessionStorage.getItem('accessToken');
    return this.http.get(`users/profile`, null, true);
  }
  setUser(value) {
    this._currentUser.next(value);
    sessionStorage.setItem('user', JSON.stringify(value));
    if (value.accessToken) {
      sessionStorage.setItem('accessToken', value.accessToken);
    }
  }

  updateTimestamp(timestamp: string) {
    const currentUser = this.getUser();
    if (currentUser) {
      currentUser.timestamp = timestamp;
      this.setUser(currentUser);
    }
  }
  redirectTOLogin() {
    this.clearStorage();
    this.router.navigate(['/auth/login']);
  }
  clearStorage() {
    sessionStorage.clear();
  }
  getAccessToken(): string | null {
    return sessionStorage.getItem('accessToken');
  }

  getUser() {
    const user = sessionStorage.getItem('user') ?? null;
    return JSON.parse(user);
  }
  getSettings() {
    return this._settings.asObservable();
  }

  setSettings(settings: any) {
    this._settings.next(settings);
  }

  getCurrentSettings() {
    return this._settings.value;
  }

  getTimeStamp() {
    return this._currentUser?.value?.timestamp;
  }
  isAuthenticated() {
    const user = this.getUser() || JSON.parse(sessionStorage.getItem('user'));
    const accessToken = sessionStorage.getItem('accessToken');
    const enabledApp = environment?.settings?.ignoreSensewareAppEnabled
      ? true
      : user?.apps['senseware']?.enable;
    // use flag in environment to ignore this check for enabled app ignoreSensewareAppEnabled
    // already added to local environment for easier debugging
    return !!accessToken && enabledApp;
  }
  logout() {
    this.http.get(`users/logout`, null, true).subscribe({
      next: () => {
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
      },
    });
  }
}
