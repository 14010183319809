import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';
import { NgxEchartsModule } from 'ngx-echarts';
import { CarouselModule as OwlCarouselModule } from 'ngx-owl-carousel-o';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { ChartViewComponent } from '../pages/charts/chart-view/chart-view.component';
import { BaseChartComponent } from './components/base-chart/base-chart.component';
import { DynamicColorRangeComponent } from './components/dynamic-color-range/dynamic-color-range.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IconModule } from './components/inline-svg-icon/icon.module';
import { LocationGoogleMapsPopupComponent } from './components/location-google-maps-popup/location-google-maps-popup.component';
import { MenuComponent } from './components/menu/menu.component';
import { NoFoundDataComponent } from './components/no-found-data/no-found-data.component';
import { NotificationCommentComponent } from './components/notification-comment/notification-comment.component';
import { ReadingIconComponent } from './components/reading-icon/reading-icon.component';
import { KeyValueOrderPipe } from './pipes/key-value-sort-by-value-order-pipe.pipe';
import { OrderPipe } from './pipes/order.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { VisiblePipe } from './pipes/visible.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';

import { OwlDayJsDateTimeModule } from '@danielmoncada/angular-datetime-picker-dayjs-adapter';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CustomDayjsDateTimeAdapter } from './custom-date-adapter';

// Extend Day.js with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// See the Day.js docs for the meaning of these formats:
// https://day.js.org/docs/en/display/format
export const MY_DAYJS_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

const PRIME_MODULES = [
  TieredMenuModule,
  SidebarModule,
  InputTextModule,
  DropdownModule,
  AvatarModule,
  ProgressSpinnerModule,
  TabViewModule,
  MenuModule,
  TooltipModule,
  CarouselModule,
  ToggleButtonModule,
  RadioButtonModule,
  MultiSelectModule,
  InputSwitchModule,
  InputTextareaModule,
  SelectButtonModule,
  CalendarModule,
  AccordionModule,
  CardModule,
  InputGroupModule,
  ToastModule,
  ConfirmDialogModule,
  TooltipModule,
  SliderModule,
  PasswordModule,
  DragDropModule,
  DialogModule,
  BadgeModule,
  TableModule,
  PaginatorModule,
  CheckboxModule,
];
const MATERIAL_MODULES = [ScrollingModule];
const ANGULAR_MODULES = [FormsModule, ReactiveFormsModule];
const CORE_MODULES = [TagInputModule, NgxTippyModule, LeafletModule];

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    ReadingIconComponent,
    NoFoundDataComponent,
    OrderPipe,
    KeyValueOrderPipe,
    VisiblePipe,
    TimeAgoPipe,
    BaseChartComponent,
    ChartViewComponent,
    DynamicColorRangeComponent,
    LocationGoogleMapsPopupComponent,
    NotificationCommentComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    ...PRIME_MODULES,
    ...MATERIAL_MODULES,
    ...ANGULAR_MODULES,
    ...CORE_MODULES,
    TranslateModule,
    NgxEchartsModule,
    OwlCarouselModule,
    OwlDateTimeModule,
    OwlDayJsDateTimeModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ReadingIconComponent,
    NoFoundDataComponent,
    BaseChartComponent,
    ...PRIME_MODULES,
    ...MATERIAL_MODULES,
    ...ANGULAR_MODULES,
    ...CORE_MODULES,
    TranslateModule,
    OrderPipe,
    KeyValueOrderPipe,
    VisiblePipe,
    TimeAgoPipe,
    NgxEchartsModule,
    ChartViewComponent,
    OwlCarouselModule,
    DynamicColorRangeComponent,
    LocationGoogleMapsPopupComponent,
    NotificationCommentComponent,
    OwlDateTimeModule,
    OwlDayJsDateTimeModule,
  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_DAYJS_FORMATS },
    { provide: DateTimeAdapter, useClass: CustomDayjsDateTimeAdapter },
  ],
})
export class SharedModule {}
